import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

import { Row, Col, Card, Button } from 'react-bootstrap';
import { Input } from '@mobiscroll/react';

import EditListing from '../../modals/EditListing';
import DeleteListing from '../../modals/DeleteListing';
import FeatureListing from '../../modals/FeatureListing';
import ShowHideListing from '../../modals/ShowHideListing';
import UpdateListingStatus from '../../modals/UpdateListingStatus';

import UserName from '../../items/userName';
import ListingThumb from '../../items/listingThumb';

//import ImageGallery from 'react-image-gallery';
import "./../../styles/image-gallery.css";

import Listing from '../../functions/Listing';

import '../../styles/site.css';

import { FaEdit } from 'react-icons/fa';
import { FaStar } from 'react-icons/fa';
import { FaTrashAlt } from 'react-icons/fa';
import { FaEyeSlash } from 'react-icons/fa';
import { FaEye } from 'react-icons/fa';
import { FaArrowRight } from 'react-icons/fa';

import { Grid } from "react-loader-spinner";

function ShowListings(props) {
    const [listings, setListings] = useState([]);
    const [defaultListings, setDefaultListings] = useState([]);

    const [listingOwner, setListingOwner] = useState([]);

    const [listingStatus, setListingStatus] = useState([]);
    const [owner, setOwner] = useState([]);
    const [loading, setLoading] = useState(false);
    const [getData, setGetData] = useState(false);
    const [listingToEdit, addListing] = useState();

    const [editListing, setEditListing] = useState(false);

    const [showDeleteListing, setShowDeleteListing] = useState(false);
    const [listingToDelete, setListingToDelete] = useState();
    const [listingToDeleteTitle, setListingToDeleteTitle] = useState();

    const [showFeatureListing, setShowFeatureListing] = useState(false);
    const [listingToFeature, setListingToFeature] = useState();
    const [listingToFeatureTitle, setListingToFeatureTitle] = useState();

    const [showShowHideListing, setShowShowHideListing] = useState(false);
    const [listingToShowHide, setListingToShowHide] = useState();
    const [listingToShowHideTitle, setListingToShowHideTitle] = useState();
    const [listingShowStatus, setListingShowStatus] = useState();

    const [showUpdateListingStatus, setShowUpdateListingStatus] = useState(false);
    const [listingToUpdateStatus, setListingToUpdateStatus] = useState();
    const [listingToUpdateStatusTitle, setListingToUpdateStatusTitle] = useState();

    const [showLoading, setShowLoading] = useState('block');

    const reloadListings = () => {
        
        setListings([]);

        if (props.loginType === 'hosting') {
            Listing.getListingByOwnerEmail(props.email).then(result => {
                setOwner(result.data.OwnerID);
                setListings(result.data.Listings);
                setShowLoading('none');
                //create a default list as this is required for filtering on text
                setDefaultListings(result.data.Listings);

                //check if sorting exists and call
                if (document.getElementById('titleSearch').value != "") {
                    filterByTextInput(document.getElementById('titleSearch').value, listings);
                }

            });
        } else if (props.loginType === 'admin') {
            Listing.getListings().then(result => {
                var listings = []
                //will need to loop through the owners to get all listings
                result.data.map((owner, index) => {
                    owner.Listings.map((listing, index) => {
                        listings.push(listing);
                    })
                })
                setListings(listings);
                setShowLoading('none');
                //create a default list as this is required for filtering on text
                setDefaultListings(listings);

                //check if sorting exists and call
                if (document.getElementById('titleSearch').value != "") {
                    filterByTextInput(document.getElementById('titleSearch').value, listings);
                }
            });
        }
    }

    useEffect(() => {
        setLoading(true);

        if (!getData) {
            reloadListings();
        }

        setGetData(true);

        setLoading(false);
        
    })

    const sortByDate = () => {
        setShowLoading('block');
        const list = listings.sort((a, b) => (a.Owner.CreatedDate < b.Owner.CreatedDate) ? 1 : -1);
        setListings([]);
        setTimeout(function () { //Start the timer
            setListings(list);
            setShowLoading('none');
        }.bind(this), 500)
        
    }

    const filterByTitle = (e) => {
        setShowLoading('block');

        const list = defaultListings.filter(function (listing) { return listing.Title.toLowerCase().includes(e.target.value.toLowerCase()); });

        setListings([]);
        setTimeout(function () { //Start the timer
            setListings(list);
            setShowLoading('none');
        }.bind(this), 0)

    }

    const filterByTextInput = (text, listings) => {
        setShowLoading('block');

        const list = listings.filter(function (listing) { return listing.Title.toLowerCase().includes(text.toLowerCase()); });

        setListings([]);
        setTimeout(function () { //Start the timer
            setListings(list);
            setShowLoading('none');
        }.bind(this), 500)

    }

    const getStatus = (owner) => {
        if (owner.Hidden == true) {
            return <span className='label label-danger'>Hidden</span>;
        }
        if (owner.Draft == true) {
            return <span className='label label-info'>Draft</span>;
        }
        if (owner.Published == true) {
            return <span className='label label-primary'>Published</span>;
        } else {
            return <span className='label label-secondary'>Pending</span>;
        }
    }

    const deleteListing = (listingTitle, listingID) => {
        //show modal
        setShowDeleteListing(true);
        setListingToDeleteTitle(listingTitle);
        //let id = listings[index].ListingID;
        setListingToDelete(listingID);
    }

    const deleteConfirmed = () => {
        setShowDeleteListing(false);
        setShowLoading('block');
        let id = listingToDelete;
        //setListings(listings.filter(listing => listing.ListingID !== id));
        Listing.deleteListingByID(id).then(() => {
            reloadListings();
        });
    }

    const hideDelete = () => {
        setShowDeleteListing(false);
    }

    const featureListing = (listingTitle, listingID) => {
        //show modal
        setShowFeatureListing(true);
        setListingToFeatureTitle(listingTitle);
        //let id = listings[index].ListingID;
        setListingToFeature(listingID);
    }

    const featureShow = () => {
        setShowFeatureListing(false);
        let id = listingToFeature;
        setShowLoading('block');
        Listing.UpdateListingState(id, "featured", true).then(() => {
            reloadListings();
        });
    }

    const featureHide = () => {
        setShowFeatureListing(false);
        let id = listingToFeature;
        setShowLoading('block');
        Listing.UpdateListingState(id, "featured", false).then(() => {
            reloadListings();
        });
    }

    const showHideListing = (listingTitle, listingID) => {
        //show modal
        setShowShowHideListing(true);
        setListingToShowHideTitle(listingTitle);
        //setListingShowStatus('need to add in DB');
        //let id = listings[index].ListingID;
        setListingToShowHide(listingID);
    }

    const showListing = () => {
        setShowShowHideListing(false);
        let id = listingToShowHide;
        setShowLoading('block');
        Listing.UpdateListingState(id, "hidden", false).then(() => {
            reloadListings();
        });
    }

    const hideListing = () => {
        setShowShowHideListing(false);
        let id = listingToShowHide;
        setShowLoading('block');
        Listing.UpdateListingState(id, "hidden", true).then(() => {
            reloadListings();
        });
    }

    const updateStatusListing = (listingTitle, listingID) => {
        //show modal
        setShowUpdateListingStatus(true);
        setListingToUpdateStatusTitle(listingTitle);
        //let id = listings[index].ListingID;
        setListingToUpdateStatus(listingID);
    }

    const publishListing = () => {
        setShowUpdateListingStatus(false);
        let id = listingToUpdateStatus;
        setShowLoading('block');
        Listing.UpdateListingState(id, "published", true).then(() => {
            reloadListings();
        });
    }

    const pendingListing = () => {
        setShowUpdateListingStatus(false);
        let id = listingToUpdateStatus;
        setShowLoading('block');
        Listing.UpdateListingState(id, "published", false).then(() => {
            reloadListings();
        });
    }

    return (
        <>
            <Card>
                <Card.Title>
                    Manage
                </Card.Title>
                <Card.Body>
                    <Row>
                        <Col>
                            <Input id="titleSearch" className="mbsc-remove-margins" label="Title Search" inputStyle="box" labelStyle="floating" placeholder="Start Typing" autoComplete="off" onChange={filterByTitle} />
                        </Col>
                        <Col>
                            <Button style={{height: "85%"}} title="Sort by Created Date" onClick={() => sortByDate()} >Sort by Created Date</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Row>
                            {props.loginType === 'admin' ?
                                <Col md="1"><h5>User</h5></Col>
                            :
                                <Col md="1"><h5>Thumbnail</h5></Col>
                            }
                            <Col md="1"><h5>Title</h5></Col>
                            <Col md="2"><h5>Address</h5></Col>
                            <Col md="1"><h5>Type</h5></Col>
                            <Col md="1"><h5>Price</h5></Col>
                            <Col md="1"><h5>Bedrooms</h5></Col>
                            <Col md="1"><h5>Baths</h5></Col>
                            <Col md="1"><h5>Guests</h5></Col>
                            <Col md="1"><h5>Status</h5></Col>
                            <Col md="2"><h5>Actions</h5></Col>
                        </Row>
                        <div class="loading-overlay" style={{ display: showLoading }}>
                            <div class="loading-vertical">
                                <Grid
                                    color="#00BFFF"
                                    height={100}
                                    width={100}
                                    timeout={0}
                                />
                            </div>
                        </div>
                        {

                            listings ?
                                listings.map((listing, index) =>
                                    //Listing.GetListingStateByListingID(listing.ListingID).then(result => {
                                    //return(
                                    <Row>
                                        {props.loginType === 'admin' ?
                                            <Col md="1">
                                                <UserName id={listing.Owner.OwnerID} />
                                            </Col>
                                            :
                                            <Col md="1">
                                                {/*<img className="search-img" height="100px" src={"data:image/png;base64," + listing.PrimaryImage} width="100%" style={{ objectFit: "cover" }}></img>*/}
                                                <ListingThumb id={listing.ListingID} />
                                                {/*{listing.ListingID}*/}
                                            </Col>
                                        }
                                        <Col md="1">
                                            {listing.Title}
                                        </Col>
                                        <Col md="2">
                                            {listing.Address ? listing.Address : <span style={{ color: '#e96852' }}>Location Data Missing</span>}
                                        </Col>
                                        <Col md="1">
                                            {listing.TypeOfListing}
                                        </Col>
                                        <Col md="1">
                                            ${listing.Price}
                                        </Col>
                                        <Col md="1">
                                            {listing.NumberOfBedrooms}
                                        </Col>
                                        <Col md="1">
                                            {listing.NumberOfBathrooms}
                                        </Col>
                                        <Col md="1">
                                            {listing.MaximumNumberOfGuests}
                                        </Col>
                                        <Col md="1">
                                            {getStatus(listing.Owner)}
                                        </Col>
                                        <Col md="2">
                                            
                                            <Link to={{
                                                pathname: "/editlisting/" + listing.ListingID
                                            }}><Button variant="link" title="Edit Listing" className="icon-button" > <FaEdit size={28} color="lightblue" /> </Button>
                                            </Link>

                                            {props.loginType === 'admin' ? <Button style={{ color: (listing.Owner.Featured ? "#ffaa00" : "lightblue") }} variant="link" title="Feature Listing" className="icon-button" onClick={() => featureListing(listing.ListingID, listing.ListingID)} ><FaStar size={28} /></Button> : null}
                                            <Button variant="link" title="Delete Listing" className="icon-button" onClick={() => deleteListing(listing.Title, listing.ListingID)} ><FaTrashAlt id="delete" size={28} color="lightblue" /></Button>
                                            <Button style={{ display: (listing.Owner.Hidden ? "none" : "inline") }} variant="link" title="Hide Listing" className="icon-button" onClick={() => showHideListing(listing.Title, listing.ListingID)} ><FaEyeSlash size={28} color="lightblue" /></Button>
                                            <Button style={{ display: (listing.Owner.Hidden ? "inline" : "none") }} variant="link" title="Show Listing" className="icon-button" onClick={() => showHideListing(listing.Title, listing.ListingID)} ><FaEye size={28} color="lightblue" /></Button>
                                            {props.loginType === 'admin' && !listing.Owner.Draft ? <Button variant="link" title="Admin Actions" className="icon-button" onClick={() => updateStatusListing(listing.Title, listing.ListingID)} ><FaArrowRight size={28} color="lightblue" /></Button> : null}
                                        </Col>
                                    </Row>
                                    // )
                                    // })

                                ) :
                                <Row>
                                    <h4>There are no Listings to show</h4>
                                </Row>
                        }
                    </Row>
                </Card.Body>
            </Card>
            <EditListing showEditListing={editListing} listing={listingToEdit} />

            <DeleteListing showDeleteListing={showDeleteListing} listing={listingToDeleteTitle} confirmed={deleteConfirmed} hide={hideDelete} />

            <FeatureListing showFeatureListing={showFeatureListing} listing={listingToFeatureTitle} confirmed={featureShow} hide={featureHide} />

            <ShowHideListing showShowHideListing={showShowHideListing} listing={listingToShowHideTitle} confirmed={showListing} hide={hideListing} current={listingShowStatus} />

            <UpdateListingStatus showUpdateListingStatus={showUpdateListingStatus} listing={listingToUpdateStatusTitle} publish={publishListing} pending={pendingListing} />
        </>
    )
}

export default ShowListings