import React, { Component, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Row, Col, Accordion } from 'react-bootstrap';

import GuestSearch from "../items/guestSearch";
import "./../styles/listing.css";
import "./../styles/bookingcal.css";

import PricingDetail from './../functions/Pricing';
import Listing from './../functions/Listing';
import Promo from './../functions/Promo';

import { Datepicker, Input, Textarea } from '@mobiscroll/react';
import { Grid } from "react-loader-spinner";
import ListingService from "../services/ListingService";

const today = new Date();

var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export function withRouter(Children) {
    return (props) => {

        const match = { params: useParams() };
        const location = { location: useLocation() };

        return <Children {...props} match={match.params} location={location.location} />
    }
}

class Booking extends Component {
    constructor(props) {
        super(props);

        this.state = {
            blockedArray: [],
            spinner: 'block',
            pricingspinner: 'none',
            showAccordion: 'none',
            showPaymentDue: 'none',
            booking: 'none',
            scrollStop: -1,
            pricingTable: 'no pricing',
            min: today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2) + 'T00:00', //'2022-01-21T00:00',//today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate(),
            max: (today.getFullYear() + 1) + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2) + 'T00:00',
            start: null,
            end: null,
            guests: props.guests ? props.guests : 0,
            pets: props.pets,
            checkin: [],
            arrive: null,
            listingID: props.id,
            nightly: 0,
            nightlyFormatted: '$0.00',
            weekend: 0,
            maxGuest: 99,
            minBooking: 0,
            maxBooking: 0,
            cancellation: 0,
            maxBookingWindow: 0,
            serviceFee: 0,
            amountSaved: 0,
            labels: [],
            invalid: [],
            colors: [],
            dailyPrice: null,
            inctaxes: null,
            extaxes: null,
            condtaxes: null,
            extaxesArrive: null,
            condtaxesArrive: null,
            incfees: null,
            exfeesArrive: null,
            condfeesArrive: null,
            exfees: null,
            condfees: null,
            total: null,
            totalGST: null,
            guestGST: null,
            hostGST: null,
            deposit: null,
            securityDeposit: null,
            requestDisabled: true,
            intro: '',
            NightlyTotal: '',
            Nights: 0,
            reservationUUID: "",
            condFeesSelect: [],
            condTaxesSelect: [],
            incGST: false,
            guestSet: false,
            petsSet: false,
            dateSet: false,
            showInstant: 'none',
            showDatesNotValid: 'none',
            showThirdPartyError: 'none',
            thirdPartyErrorMessage: "",
            remoteToken: null,
            totalDsicount: 0,
            pmsFees: 0
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.stopScroll);

        var arrive = null;
        var depart = null;

        if (this.props.checkin) {
            //break the dates down
            var datestring = this.props.checkin.toString();

            this.setState({ arrive: this.props.checkin });

            var dates = datestring.split(',');
            arrive = new Date(dates[0]);

            if (dates[1]) {
                depart = new Date(dates[1]);

                var val1 = (arrive.getFullYear() + '-' + ('0' + (arrive.getMonth() + 1)).slice(-2) + '-' + ('0' + arrive.getDate()).slice(-2)).toString();
                var val2 = (depart.getFullYear() + '-' + ('0' + (depart.getMonth() + 1)).slice(-2) + '-' + ('0' + depart.getDate()).slice(-2)).toString();

                this.setState({
                    checkin: [val1, val2]
                });
            } else {
                this.setState({ checkin: [(arrive.getFullYear() + '-' + ('0' + (arrive.getMonth() + 1)).slice(-2) + '-' + ('0' + arrive.getDate()).slice(-2)).toString()] });
            }
        }

        //fill in calendar details
        Listing.getListingByID(this.state.listingID).then((result) => {
            if (result.data.Pricing.InstantBooking) {
                this.setState({
                    showInstant: 'block',
                })
            }

            this.setState({
                nightly: result.data.Pricing.Nightly,
                weekend: result.data.Pricing.Weekend,
                minBooking: parseInt(result.data.TermsAndConditions.MinimumBooking, 10) + 1,
                maxBooking: parseInt(result.data.TermsAndConditions.MaximumBooking, 10),
                maxBookingWindow: result.data.TermsAndConditions.MaxBookingWindows,
                securityDeposit: result.data.Pricing.SecurityDeposit,
                incGST: result.data.Pricing.GSTFlag,
                cancellation: result.data.TermsAndConditions.Cancellation,
                remoteToken: result.data.Owner.RemoteToken
            });


            //when setting max number of guests check if allowed is selected first - this prevents the user setting the field but stating NOT ALLOWED
            if (result.data.Pricing.AllowAdditionalGuests == "Yes") {
                this.setState({
                    maxGuest: result.data.Pricing.MaximumNumberOfGuests
                })
            } else {
                this.setState({
                    maxGuest: result.data.Pricing.StandardNumberOfGuests
                })
            }

            var today = new Date();
            var max = new Date(today);

            //check the max booking window is all and update below
            if (result.data.TermsAndConditions.MaxBookingWindows == "All") {
                max.setMonth(today.getMonth() + 24);
            } else {
                max.setMonth(today.getMonth() + +result.data.TermsAndConditions.MaxBookingWindows);
            }

            //set min and max nightly price for range
            var minRate = 0;
            var maxRate = 0;
            if (result.data.Pricing.Nightly && result.data.Pricing.Weekend) {
                if (result.data.Pricing.Nightly < result.data.Pricing.Weekend) {
                    minRate = result.data.Pricing.Nightly;
                    maxRate = result.data.Pricing.Weekend
                } else {
                    minRate = result.data.Pricing.Weekend;
                    maxRate = result.data.Pricing.Nightly
                }
            } else if (result.data.Pricing.Nightly) {
                minRate = result.data.Pricing.Nightly;
                maxRate = result.data.Pricing.Nightly
            } else if (result.data.Pricing.Weekend) {
                minRate = result.data.Pricing.Weekend;
                maxRate = result.data.Pricing.Weekend
            }

            //get listing periods
            var periodArray = [];
            Listing.getListingPeriodsByID(
                this.state.listingID,
                (('0' + (today.getMonth() + 1)).slice(-2) + '/' + ('0' + today.getDate()).slice(-2) + '/' + today.getFullYear()).toString(),
                (('0' + (max.getMonth() + 1)).slice(-2) + '/' + ('0' + max.getDate()).slice(-2) + '/' + max.getFullYear()).toString()
            ).then((periods) => {
                if (periods && typeof (periods.data) != 'string') {

                    periods.data.map(function (item, i) {
                        var date = item.Date.toString().split('T');
                        periodArray = [...periodArray, date[0]];

                        var nightly = 0;//parseInt(item.Nightly);
                        var weekend = 0;//parseInt(item.Weekend);

                        if (item.Nightly)
                            nightly = parseInt(item.Nightly);

                        if (item.Weekend)
                            weekend = parseInt(item.Weekend);

                        //find the min and max rates
                        if (nightly != 0) {
                            if (nightly < minRate) {
                                minRate = item.Nightly;
                            } else if (nightly > maxRate) {
                                maxRate = item.Nightly;
                            }
                        }

                        if (weekend != 0) {
                            if (weekend < minRate) {
                                minRate = item.Weekend;
                            } else if (weekend > maxRate) {
                                maxRate = item.Weekend;
                            }
                        }

                    })


                } else {
                    //console.log('no periods');
                }

                PricingDetail.formatPrice(minRate).then((resultMin) => {
                    minRate = resultMin;
                    PricingDetail.formatPrice(maxRate).then((resultMax) => {
                        maxRate = resultMax;
                    this.setState({ nightlyFormatted: (minRate + '-' + maxRate) });
                    });
                });

                const blockedDates = result.data.Calendar;
                //strip out dates only for simple matching below
                var blockedArray = [];
                blockedDates.map(function (item, i) {
                    var date = new Date(item.Date);
                    blockedArray = [...blockedArray, (('0' + (date.getMonth() + 1)).slice(-2) + '/' + ('0' + date.getDate()).slice(-2) + '/' + date.getFullYear()).toString()];
                })

                //add blocked array to state for testing when getting pricing - this will save more processing and a call to the DB
                this.setState({ blockedArray: blockedArray });


                //set labels for pricing
                //build array to set state once
                var buildLabels = [];
                var buildInvalid = [];
                var buildColors = [];

                //block out the minimum notice period and start the blocked dates from this date
                var minNotice = new Date(today);
                minNotice.setDate(today.getDate() + +result.data.TermsAndConditions.MinimumNotice);

                for (let i = today; i < minNotice; (i.setDate(i.getDate() + 1))) {
                    buildInvalid = [...buildInvalid, {
                        date: new Date(i.getFullYear(), i.getMonth(), i.getDate())
                    }]
                }


                for (let i = minNotice; i < max; (i.setDate(i.getDate() + 1))) {

                    //check if the date is blocked or has a custom rate
                    var dateCompare = (('0' + (i.getMonth() + 1)).slice(-2) + '/' + ('0' + i.getDate()).slice(-2) + '/' + i.getFullYear()).toString();
                    var prevDate = (('0' + (i.getMonth() + 1)).slice(-2) + '/' + ('0' + (i.getDate() - 1)).slice(-2) + '/' + i.getFullYear()).toString();
                    var nextDate = (('0' + (i.getMonth() + 1)).slice(-2) + '/' + ('0' + (i.getDate() + 1)).slice(-2) + '/' + i.getFullYear()).toString();

                    //if the date is in the blocked list set colours
                    if (blockedArray.indexOf(dateCompare) > -1) {
                        buildLabels = [...buildLabels, {
                            date: new Date(i.getFullYear(), i.getMonth(), i.getDate()),
                            text: 'booked',
                            textColor: '#1e1e1ecc'
                        }]

                        if ((blockedArray.indexOf(prevDate) > -1) && (blockedArray.indexOf(nextDate) > -1)) {
                            //this is a middle date
                            buildColors = [...buildColors, {
                                date: new Date(i.getFullYear(), i.getMonth(), i.getDate()),
                                background: '#ffbaba80',
                                cellCssClass: 'vacation-booked'
                            }, {
                                date: new Date(i.getFullYear(), i.getMonth(), i.getDate()),
                                background: '#b2f1c080',
                                cellCssClass: 'md-book-rental-bg-off'
                            }]

                            //blocking the middle dates only to allow user to select the checkin and checkout
                            buildInvalid = [...buildInvalid, {
                                date: new Date(i.getFullYear(), i.getMonth(), i.getDate())
                            }]

                        } else if ((blockedArray.indexOf(prevDate) > -1) && !(blockedArray.indexOf(nextDate) > -1)) {
                            //this is a middle date
                            buildColors = [...buildColors, {
                                date: new Date(i.getFullYear(), i.getMonth(), i.getDate()),
                                background: '#ffbaba80',
                                cellCssClass: 'vacation-booked'
                            }, {
                                date: new Date(i.getFullYear(), i.getMonth(), i.getDate()),
                                background: '#b2f1c080',
                                cellCssClass: 'md-book-rental-bg-off'
                            }]

                            //blocking the middle dates only to allow user to select the checkin and checkout
                            buildInvalid = [...buildInvalid, {
                                date: new Date(i.getFullYear(), i.getMonth(), i.getDate())
                            }]

                        } else if (!(blockedArray.indexOf(prevDate) > -1) && !(blockedArray.indexOf(nextDate) > -1)) {
                            //this is a start of a single night date
                            buildColors = [...buildColors, {
                                date: new Date(i.getFullYear(), i.getMonth(), i.getDate()),
                                //date: new Date(2022, 1, 12),
                                cellCssClass: 'vacation-check-in'
                            }, {
                                date: new Date(i.getFullYear(), i.getMonth(), i.getDate()),
                                background: '#b2f1c080',
                                cellCssClass: 'md-book-rental-bg-off'
                            }]

                        } else if (blockedArray.indexOf(nextDate) > -1) {
                            //next exist therefore this is a first date
                            buildColors = [...buildColors, {
                                date: new Date(i.getFullYear(), i.getMonth(), i.getDate()),
                                //date: new Date(2022, 1, 12),
                                cellCssClass: 'vacation-check-in'
                            }, {
                                date: new Date(i.getFullYear(), i.getMonth(), i.getDate()),
                                background: '#b2f1c080',
                                cellCssClass: 'md-book-rental-bg-off'
                            }]

                            //blocking the middle dates only to allow user to select the checkin and checkout
                            //buildInvalid = [...buildInvalid, {
                            //    date: new Date(i.getFullYear(), i.getMonth(), i.getDate())
                            //}]

                        } else if (blockedArray.indexOf(prevDate) > -1) {
                            //previous exist therefore a last date
                            buildColors = [...buildColors, {
                                date: new Date(i.getFullYear(), i.getMonth(), i.getDate()),
                                cellCssClass: 'vacation-check-out'
                            }, {
                                date: new Date(i.getFullYear(), i.getMonth(), i.getDate()),
                                background: '#b2f1c080',
                                cellCssClass: 'md-book-rental-bg-off'
                            }]
                        } else {
                            //blocking the middle dates only to allow user to select the checkin and checkout
                            buildInvalid = [...buildInvalid, {
                                date: new Date(i.getFullYear(), i.getMonth(), i.getDate())
                            }]
                        }

                    } else if (blockedArray.indexOf(prevDate) > -1) {
                        //previous exist therefore a last date
                        buildColors = [...buildColors, {
                            date: new Date(i.getFullYear(), i.getMonth(), i.getDate()),
                            cellCssClass: 'vacation-check-out'
                        }, {
                            date: new Date(i.getFullYear(), i.getMonth(), i.getDate()),
                            background: '#b2f1c080',
                            cellCssClass: 'md-book-rental-bg-off'
                        }]
                    } else {

                        //Get the date formatted to compare to period
                        var periodCompare = (i.getFullYear() + '-' + ('0' + (i.getMonth() + 1)).slice(-2) + '-' + ('0' + i.getDate()).slice(-2)).toString()

                        //Reset the nightly and weekend rates each loop
                        var nightlyRate = result.data.Pricing.Nightly;
                        var weekendRate = result.data.Pricing.Weekend;

                        if (!weekendRate || weekendRate == 0) {
                            weekendRate = nightlyRate;
                        }

                        //Compare and change rates if exists
                        if (periodArray.indexOf(periodCompare) > -1) {

                            var night = periods.data[periodArray.indexOf(periodCompare)].Nightly;
                            var weekend = periods.data[periodArray.indexOf(periodCompare)].Weekend;

                            if (night && night != 0) {
                                nightlyRate = night;
                            }
                            if (weekend && weekend != 0) {
                                weekendRate = weekend;
                            }

                            if (!weekendRate) {
                                weekendRate = nightlyRate;
                            }
                        } 

                        nightlyRate = formatter.format(nightlyRate);
                        weekendRate = formatter.format(weekendRate);

                        //check what the listing has as weekends
                        //Friday = 5, Saturday = 6, Sunday = 0, 
                        var weekend = result.data.Pricing.WeekendRate;
                        if (i.getDay() == 5 && weekend.includes('Friday')) {
                            buildLabels = [...buildLabels, {
                                date: new Date(i.getFullYear(), i.getMonth(), i.getDate()),
                                text: weekendRate,
                                textColor: '#1e1e1ecc'
                            }]
                        } else if (i.getDay() == 6 && weekend.includes('Saturday')) {
                            buildLabels = [...buildLabels, {
                                date: new Date(i.getFullYear(), i.getMonth(), i.getDate()),
                                text: weekendRate,
                                textColor: '#1e1e1ecc'
                            }]
                        } else if (i.getDay() == 0 && weekend.includes('Sunday')) {
                            buildLabels = [...buildLabels, {
                                date: new Date(i.getFullYear(), i.getMonth(), i.getDate()),
                                text: weekendRate,
                                textColor: '#1e1e1ecc'
                            }]
                        } else {
                            buildLabels = [...buildLabels, {
                                date: new Date(i.getFullYear(), i.getMonth(), i.getDate()),
                                text: nightlyRate,
                                textColor: '#1e1e1ecc'
                            }]
                        }

                        buildColors = [...buildColors, {
                            date: new Date(i.getFullYear(), i.getMonth(), i.getDate()),
                            background: '#b2f1c080',
                            cellCssClass: 'md-book-rental-bg-off'
                        }]


                    }

                }

                this.setState({
                    labels: buildLabels,
                    invalid: buildInvalid,
                    colors: buildColors,
                    spinner: 'none',
                    booking: 'block',
                    max: max.getFullYear() + '-' + ('0' + (max.getMonth() + 1)).slice(-2) + '-' + ('0' + max.getDate()).slice(-2) + 'T00:00'
                })

            });
        });
    }

    componentDidUpdate() {
        if (this.state.reservationUUID != this.props.reservationUUID) {
            this.setState({
                reservationUUID: this.props.reservationUUID,
                spinner: 'block',
                booking: 'none',
                showAccordion: 'none',
                guests: "",
                checkin: [],
                requestDisabled: true,
                intro: '',
                pets: "",
                arrive: ''
            });
            this.refs.pricing.innerHTML = "";

            this.componentDidMount();

        }

        //set dates
        var arrive = null;
        var depart = null;
        if (this.state.arrive != this.props.checkin && this.props.checkin && !this.state.dateSet) {
        //    //break the dates down
            var datestring = this.props.checkin.toString();

            //console.log("checkin: " + this.props.checkin);

            this.setState({ arrive: this.props.checkin });

            var dates = datestring.split(',');
            arrive = new Date(dates[0]);

            //alert(this.props.checkin.toString());

            if (dates[1]) {
                depart = new Date(dates[1]);

                var val1 = (arrive.getFullYear() + '-' + ('0' + (arrive.getMonth() + 1)).slice(-2) + '-' + ('0' + arrive.getDate()).slice(-2)).toString();
                var val2 = (depart.getFullYear() + '-' + ('0' + (depart.getMonth() + 1)).slice(-2) + '-' + ('0' + depart.getDate()).slice(-2)).toString();
        //        alert(val1);
                this.setState({
                    checkin: [val1, val2]
                }, () => {
                    this.getPricing();
                });
            } else {
                this.setState({ checkin: [(arrive.getFullYear() + '-' + ('0' + (arrive.getMonth() + 1)).slice(-2) + '-' + ('0' + arrive.getDate()).slice(-2)).toString()] });
            }
            this.setState({ dateSet: true });
        }

        //set guests
        if (this.state.guests != this.props.guests && !this.state.guestSet) {
            this.setState({
                guests: this.props.guests,
                guestSet: true
            }, () => {
                this.getPricing();
            });
        }
        //set pets
        if (this.state.pets != this.props.pets && !this.state.petsSet) {
            this.setState({
                pets: this.props.pets,
                petsSet: true
            });
        }
    }

    //onCalendarListing = () => {
        
    //}

    stopScroll = (event) => {
        let scrollTop = event.target.scrollingElement.scrollTop,
            itemTranslate = Math.min(0, scrollTop - 580);
        
        this.setState({
            scrollStop: itemTranslate
        })
    }

    getPricing = (input) => {

        //put in loader and clean previous output
        this.setState({
            pricingspinner: 'block',
            showAccordion: 'none',
            showPaymentDue: 'none',
            showThirdPartyError: 'none',
            thirdPartyErrorMessage: ""
        });
        this.refs.pricing.innerHTML = "";
        
        this.setState({ requestDisabled: true });

        var arrive = null;
        var tempArrive = null;
        var depart = null;

        if (this.state.arrive || input) {
            //break the dates down
            var datestring = null;
            if (input) {
                datestring = input.toString();
            } else {
                datestring = this.state.arrive.toString();
            }
            var dates = datestring.split(',');

            //convert to date object
            arrive = new Date(dates[0]);
            //somehow the arrive date gets updated so setting a temp variable to avoid this
            tempArrive = new Date(dates[0]);
            if (dates[1]) {
                depart = new Date(dates[1]);
            } else {
                depart = null;
            }
        }

        if (arrive && depart && this.state.guests && this.state.guests > 0) {
            var dateExist = 'none';

            //check if dates are in the blocked range
            for (let i = tempArrive; i < depart; (i.setDate(i.getDate() + 1))) {

                //check if the date is blocked or has a custom rate
                var dateCompare = (('0' + (i.getMonth() + 1)).slice(-2) + '/' + ('0' + i.getDate()).slice(-2) + '/' + i.getFullYear()).toString();

                //if the date is in the blocked list show message to user
                if (this.state.blockedArray.indexOf(dateCompare) > -1) {
                    dateExist = 'block';
                } 
            }
            this.setState({ showDatesNotValid: dateExist });

            //do not get pricing if date is blocked
            if (dateExist != 'block') {

                //check if the listing has a third party connection and run update check
                if (this.state.remoteToken && this.state.remoteToken.includes("RMS")) {
                    Listing.UpdateRMSData(this.state.listingID, arrive, depart).then(result => {
                        if (result && result.data && result.data.includes('calendar updated')) {
                            Listing.getCalendarByListingID(this.state.listingID).then(resultCalendar => {
                                //need to reconfirm the blocked dates at this point also before pricing can be calculated
                                if (resultCalendar && resultCalendar.data) {
                                const blockedDates = resultCalendar.data;
                                //strip out dates only for simple matching below
                                var blockedArray = [];
                                blockedDates.map(function (item, i) {
                                    var date = new Date(item.Date);
                                    blockedArray = [...blockedArray, (('0' + (date.getMonth() + 1)).slice(-2) + '/' + ('0' + date.getDate()).slice(-2) + '/' + date.getFullYear()).toString()];
                                })

                                for (let i = tempArrive; i < depart; (i.setDate(i.getDate() + 1))) {
                                    //check if the date is blocked or has a custom rate
                                    var dateCompare = (('0' + (i.getMonth() + 1)).slice(-2) + '/' + ('0' + i.getDate()).slice(-2) + '/' + i.getFullYear()).toString();
                                    //if the date is in the blocked list show message to user
                                    if (blockedArray.indexOf(dateCompare) > -1) {
                                        dateExist = 'block';
                                    }
                                }
                                this.setState({ showDatesNotValid: dateExist });

                                //call the get pricing now as all data should be up to date
                                if (dateExist == 'none') {
                                    PricingDetail.getPricingForBooking(this.state.listingID, arrive, depart, this.state.guests, this.state.pets, this.state.condFeesSelect, this.state.condTaxesSelect, this.state.incGST, this.state.validPromo).then(result => {

                                        //check if the result was error or not
                                        if (typeof (result) == 'string') {
                                            this.refs.pricing.innerHTML = '<span style="color:red">' + result + '</span>';
                                            this.setState({ pricingspinner: 'none' });
                                        } else {

                                            this.setState({
                                                dailyPrice: result[0].DailyRates,
                                                inctaxes: result[0].IncTaxes,
                                                extaxes: result[0].ExTaxes,
                                                condtaxes: result[0].CondTaxes,
                                                extaxesArrive: result[0].ExArriveTaxes,
                                                condtaxesArrive: result[0].CondArriveTaxes,
                                                incfees: result[0].IncFees,
                                                exfees: result[0].ExFees,
                                                condfees: result[0].CondFees,
                                                exfeesArrive: result[0].ExArriveFees,
                                                condfeesArrive: result[0].CondArriveFees,
                                                total: result[0].Total,
                                                totalGST: result[0].TotalGST,
                                                guestGST: result[0].GuestGST,
                                                hostGST: result[0].HostGST,
                                                deposit: result[0].Deposit,
                                                serviceFee: result[0].ServiceFee,
                                                amountSaved: result[0].Saved,
                                                pmsFees: result[0].PMSFees,
                                            });

                                            PricingDetail.formatPrice(result[0].PromoTotal).then((result) => {
                                                this.setState({ totalDsicount: result });
                                            });

                                            //check if deposit and show when final payment due
                                            if (result[0].Deposit > 0) {
                                                this.setState({ showPaymentDue: 'block' });
                                            }

                                            PricingDetail.buildDailyRateAccordion(result[0].DailyRates, result[0].Saved).then(result => {
                                                if (result) {
                                                    this.setState({
                                                        NightlyTotal: result[0],
                                                        Nights: result[2],
                                                        showAccordion: 'block'
                                                    });
                                                    this.refs.dailyrates.innerHTML = result[1];
                                                }
                                            })

                                            PricingDetail.buildPricingTable(result[0].IncTaxes, result[0].ExTaxes, result[0].CondTaxes, result[0].ExArriveTaxes, result[0].CondArriveTaxes, result[0].IncFees, result[0].ExFees, result[0].CondFees, result[0].ExArriveFees, result[0].CondArriveFees, result[0].Total, result[0].Deposit, this.state.securityDeposit, false, this.state.condFeesSelect, this.state.condTaxesSelect, result[0].TotalGST, result[0].PMSFees).then(result => {

                                                if (result) {
                                                    this.refs.pricing.innerHTML = result;
                                                    this.setState({ requestDisabled: false });
                                                }

                                                this.setState({ pricingspinner: 'none' });

                                            });

                                        }
                                    });
                                }
                                } else {
                                    //show error on third party system check
                                    this.setState({
                                        showThirdPartyError: 'block',
                                        thirdPartyErrorMessage: "There seems to be an issue connecting to the third party pricing system at this time, please try again later."
                                    });
                                    this.setState({ pricingspinner: 'none' });
                                }
                            })
                        } else if (result && result.data && result.data.includes('problem updating calendar')) {
                            //still do pricing - with different error message
                            PricingDetail.getPricingForBooking(this.state.listingID, arrive, depart, this.state.guests, this.state.pets, this.state.condFeesSelect, this.state.condTaxesSelect, this.state.incGST, this.state.validPromo).then(result => {

                                //check if the result was error or not
                                if (typeof (result) == 'string') {
                                    this.refs.pricing.innerHTML = '<span style="color:red">' + result + '</span>';
                                    this.setState({ pricingspinner: 'none' });
                                } else {

                                    this.setState({
                                        dailyPrice: result[0].DailyRates,
                                        inctaxes: result[0].IncTaxes,
                                        extaxes: result[0].ExTaxes,
                                        condtaxes: result[0].CondTaxes,
                                        extaxesArrive: result[0].ExArriveTaxes,
                                        condtaxesArrive: result[0].CondArriveTaxes,
                                        incfees: result[0].IncFees,
                                        exfees: result[0].ExFees,
                                        condfees: result[0].CondFees,
                                        exfeesArrive: result[0].ExArriveFees,
                                        condfeesArrive: result[0].CondArriveFees,
                                        total: result[0].Total,
                                        totalGST: result[0].TotalGST,
                                        guestGST: result[0].GuestGST,
                                        hostGST: result[0].HostGST,
                                        deposit: result[0].Deposit,
                                        serviceFee: result[0].ServiceFee,
                                        amountSaved: result[0].Saved,
                                        pmsFees: result[0].PMSFees,
                                    });

                                    PricingDetail.formatPrice(result[0].PromoTotal).then((result) => {
                                        this.setState({ totalDsicount: result });
                                    });

                                    //check if deposit and show when final payment due
                                    if (result[0].Deposit > 0) {
                                        this.setState({ showPaymentDue: 'block' });
                                    }

                                    PricingDetail.buildDailyRateAccordion(result[0].DailyRates, result[0].Saved).then(result => {
                                        if (result) {
                                            this.setState({
                                                NightlyTotal: result[0],
                                                Nights: result[2],
                                                showAccordion: 'block'
                                            });
                                            this.refs.dailyrates.innerHTML = result[1];
                                        }
                                    })

                                    PricingDetail.buildPricingTable(result[0].IncTaxes, result[0].ExTaxes, result[0].CondTaxes, result[0].ExArriveTaxes, result[0].CondArriveTaxes, result[0].IncFees, result[0].ExFees, result[0].CondFees, result[0].ExArriveFees, result[0].CondArriveFees, result[0].Total, result[0].Deposit, this.state.securityDeposit, false, this.state.condFeesSelect, this.state.condTaxesSelect, result[0].TotalGST, result[0].PMSFees).then(result => {

                                        if (result) {
                                            this.refs.pricing.innerHTML = result;
                                            this.setState({ requestDisabled: false });
                                        }

                                        this.setState({ pricingspinner: 'none' });

                                    });

                                }
                                //show error on third party system check
                                this.setState({
                                    showThirdPartyError: 'block',
                                    thirdPartyErrorMessage: "There seems to be an issue connecting to the third party pricing system at this time to update pricing. Pricing will be confirmed by host on accepting the reservation."
                                });
                                this.setState({ pricingspinner: 'none' });
                            });
                        } else {
                            //show error on third party system check
                            this.setState({
                                showThirdPartyError: 'block',
                                thirdPartyErrorMessage: "There seems to be an issue connecting to the third party pricing system at this time, please try again later."
                            });
                            this.setState({ pricingspinner: 'none' });
                        }
                    })

                } else if (this.state.remoteToken && this.state.remoteToken.includes("BEDS")) {
                    Listing.UpdateBEDSData(this.state.listingID, arrive, depart).then(result => {
                        //console.log(result.data);
                        //alert("updated BEDS Data")
                        if (result && result.data && result.data.includes('calendar updated')) {
                            Listing.getCalendarByListingID(this.state.listingID).then(resultCalendar => {
                                //need to reconfirm the blocked dates at this point also before pricing can be calculated
                                if (resultCalendar && resultCalendar.data) {
                                    const blockedDates = resultCalendar.data;
                                    //strip out dates only for simple matching below
                                    var blockedArray = [];
                                    blockedDates.map(function (item, i) {
                                        var date = new Date(item.Date);
                                        blockedArray = [...blockedArray, (('0' + (date.getMonth() + 1)).slice(-2) + '/' + ('0' + date.getDate()).slice(-2) + '/' + date.getFullYear()).toString()];
                                    })

                                    for (let i = tempArrive; i < depart; (i.setDate(i.getDate() + 1))) {
                                        //check if the date is blocked or has a custom rate
                                        var dateCompare = (('0' + (i.getMonth() + 1)).slice(-2) + '/' + ('0' + i.getDate()).slice(-2) + '/' + i.getFullYear()).toString();
                                        //if the date is in the blocked list show message to user
                                        if (blockedArray.indexOf(dateCompare) > -1) {
                                            dateExist = 'block';
                                        }
                                    }
                                    this.setState({ showDatesNotValid: dateExist });

                                    //call the get pricing now as all data should be up to date
                                    if (dateExist == 'none') {
                                        PricingDetail.getPricingForBooking(this.state.listingID, arrive, depart, this.state.guests, this.state.pets, this.state.condFeesSelect, this.state.condTaxesSelect, this.state.incGST, this.state.validPromo).then(result => {

                                            //check if the result was error or not
                                            if (typeof (result) == 'string') {
                                                this.refs.pricing.innerHTML = '<span style="color:red">' + result + '</span>';
                                                this.setState({ pricingspinner: 'none' });
                                            } else {

                                                this.setState({
                                                    dailyPrice: result[0].DailyRates,
                                                    inctaxes: result[0].IncTaxes,
                                                    extaxes: result[0].ExTaxes,
                                                    condtaxes: result[0].CondTaxes,
                                                    extaxesArrive: result[0].ExArriveTaxes,
                                                    condtaxesArrive: result[0].CondArriveTaxes,
                                                    incfees: result[0].IncFees,
                                                    exfees: result[0].ExFees,
                                                    condfees: result[0].CondFees,
                                                    exfeesArrive: result[0].ExArriveFees,
                                                    condfeesArrive: result[0].CondArriveFees,
                                                    total: result[0].Total,
                                                    totalGST: result[0].TotalGST,
                                                    guestGST: result[0].GuestGST,
                                                    hostGST: result[0].HostGST,
                                                    deposit: result[0].Deposit,
                                                    serviceFee: result[0].ServiceFee,
                                                    amountSaved: result[0].Saved,
                                                    pmsFees: result[0].PMSFees,
                                                });

                                                PricingDetail.formatPrice(result[0].PromoTotal).then((result) => {
                                                    this.setState({ totalDsicount: result });
                                                });



                                                //check if deposit and show when final payment due
                                                if (result[0].Deposit > 0) {
                                                    this.setState({ showPaymentDue: 'block' });
                                                }

                                                PricingDetail.buildDailyRateAccordion(result[0].DailyRates, result[0].Saved).then(result => {
                                                    if (result) {
                                                        this.setState({
                                                            NightlyTotal: result[0],
                                                            Nights: result[2],
                                                            showAccordion: 'block'
                                                        });
                                                        this.refs.dailyrates.innerHTML = result[1];
                                                    }
                                                })

                                                PricingDetail.buildPricingTable(result[0].IncTaxes, result[0].ExTaxes, result[0].CondTaxes, result[0].ExArriveTaxes, result[0].CondArriveTaxes, result[0].IncFees, result[0].ExFees, result[0].CondFees, result[0].ExArriveFees, result[0].CondArriveFees, result[0].Total, result[0].Deposit, this.state.securityDeposit, false, this.state.condFeesSelect, this.state.condTaxesSelect, result[0].TotalGST, result[0].PMSFees).then(result => {

                                                    if (result) {
                                                        this.refs.pricing.innerHTML = result;
                                                        this.setState({ requestDisabled: false });
                                                    }

                                                    this.setState({ pricingspinner: 'none' });

                                                });

                                            }
                                        });
                                    }
                                } else {
                                    //show error on third party system check
                                    this.setState({
                                        showThirdPartyError: 'block',
                                        thirdPartyErrorMessage: "There seems to be an issue connecting to the third party pricing system at this time, please try again later."
                                    });
                                    this.setState({ pricingspinner: 'none' });
                                }
                            })
                        } else if (result && result.data && result.data.includes('problem updating calendar')) {
                            //still do pricing - with different error message
                            PricingDetail.getPricingForBooking(this.state.listingID, arrive, depart, this.state.guests, this.state.pets, this.state.condFeesSelect, this.state.condTaxesSelect, this.state.incGST, this.state.validPromo).then(result => {

                                //check if the result was error or not
                                if (typeof (result) == 'string') {
                                    this.refs.pricing.innerHTML = '<span style="color:red">' + result + '</span>';
                                    this.setState({ pricingspinner: 'none' });
                                } else {

                                    this.setState({
                                        dailyPrice: result[0].DailyRates,
                                        inctaxes: result[0].IncTaxes,
                                        extaxes: result[0].ExTaxes,
                                        condtaxes: result[0].CondTaxes,
                                        extaxesArrive: result[0].ExArriveTaxes,
                                        condtaxesArrive: result[0].CondArriveTaxes,
                                        incfees: result[0].IncFees,
                                        exfees: result[0].ExFees,
                                        condfees: result[0].CondFees,
                                        exfeesArrive: result[0].ExArriveFees,
                                        condfeesArrive: result[0].CondArriveFees,
                                        total: result[0].Total,
                                        totalGST: result[0].TotalGST,
                                        guestGST: result[0].GuestGST,
                                        hostGST: result[0].HostGST,
                                        deposit: result[0].Deposit,
                                        serviceFee: result[0].ServiceFee,
                                        amountSaved: result[0].Saved,
                                        pmsFees: result[0].PMSFees,
                                    });

                                    PricingDetail.formatPrice(result[0].PromoTotal).then((result) => {
                                        this.setState({ totalDsicount: result });
                                    });

                                    //check if deposit and show when final payment due
                                    if (result[0].Deposit > 0) {
                                        this.setState({ showPaymentDue: 'block' });
                                    }

                                    PricingDetail.buildDailyRateAccordion(result[0].DailyRates, result[0].Saved).then(result => {
                                        if (result) {
                                            this.setState({
                                                NightlyTotal: result[0],
                                                Nights: result[2],
                                                showAccordion: 'block'
                                            });
                                            this.refs.dailyrates.innerHTML = result[1];
                                        }
                                    })

                                    PricingDetail.buildPricingTable(result[0].IncTaxes, result[0].ExTaxes, result[0].CondTaxes, result[0].ExArriveTaxes, result[0].CondArriveTaxes, result[0].IncFees, result[0].ExFees, result[0].CondFees, result[0].ExArriveFees, result[0].CondArriveFees, result[0].Total, result[0].Deposit, this.state.securityDeposit, false, this.state.condFeesSelect, this.state.condTaxesSelect, result[0].TotalGST, result[0].PMSFees).then(result => {

                                        if (result) {
                                            this.refs.pricing.innerHTML = result;
                                            this.setState({ requestDisabled: false });
                                        }

                                        this.setState({ pricingspinner: 'none' });

                                    });

                                }
                                //show error on third party system check
                                this.setState({
                                    showThirdPartyError: 'block',
                                    thirdPartyErrorMessage: "There seems to be an issue connecting to the third party pricing system at this time to update pricing. Pricing will be confirmed by host on accepting the reservation."
                                });
                                this.setState({ pricingspinner: 'none' });
                            });
                        } else {
                            //show error on third party system check
                            this.setState({
                                showThirdPartyError: 'block',
                                thirdPartyErrorMessage: "There seems to be an issue connecting to the third party pricing system at this time, please try again later."
                            });
                            this.setState({ pricingspinner: 'none' });
                        }
                    })

                } else {

                    PricingDetail.getPricingForBooking(this.state.listingID, arrive, depart, this.state.guests, this.state.pets, this.state.condFeesSelect, this.state.condTaxesSelect, this.state.incGST, this.state.validPromo).then(result => {

                        //check if the result was error or not
                        if (typeof (result) == 'string') {
                            this.refs.pricing.innerHTML = '<span style="color:red">' + result + '</span>';
                            this.setState({ pricingspinner: 'none' });
                        } else {

                            this.setState({
                                dailyPrice: result[0].DailyRates,
                                inctaxes: result[0].IncTaxes,
                                extaxes: result[0].ExTaxes,
                                condtaxes: result[0].CondTaxes,
                                extaxesArrive: result[0].ExArriveTaxes,
                                condtaxesArrive: result[0].CondArriveTaxes,
                                incfees: result[0].IncFees,
                                exfees: result[0].ExFees,
                                condfees: result[0].CondFees,
                                exfeesArrive: result[0].ExArriveFees,
                                condfeesArrive: result[0].CondArriveFees,
                                total: result[0].Total,
                                totalGST: result[0].TotalGST,
                                guestGST: result[0].GuestGST,
                                hostGST: result[0].HostGST,
                                deposit: result[0].Deposit,
                                serviceFee: result[0].ServiceFee,
                                amountSaved: result[0].Saved,
                                pmsFees: result[0].PMSFees,
                            });

                            PricingDetail.formatPrice(result[0].PromoTotal).then((result) => {
                                this.setState({ totalDsicount: result });
                            });

                            //check if deposit and show when final payment due
                            if (result[0].Deposit > 0) {
                                this.setState({ showPaymentDue: 'block' });
                            }

                            PricingDetail.buildDailyRateAccordion(result[0].DailyRates, result[0].Saved).then(result => {
                                if (result) {
                                    //console.log("accordion: " + result);
                                    this.setState({
                                        NightlyTotal: result[0],
                                        Nights: result[2],
                                        showAccordion: 'block'
                                    });
                                    this.refs.dailyrates.innerHTML = result[1];
                                    //this.refs.nightlytotal.innerHTML = result[0];
                                }
                            })

                            PricingDetail.buildPricingTable(result[0].IncTaxes, result[0].ExTaxes, result[0].CondTaxes, result[0].ExArriveTaxes, result[0].CondArriveTaxes, result[0].IncFees, result[0].ExFees, result[0].CondFees, result[0].ExArriveFees, result[0].CondArriveFees, result[0].Total, result[0].Deposit, this.state.securityDeposit, false, this.state.condFeesSelect, this.state.condTaxesSelect, result[0].TotalGST, result[0].PMSFees).then(result => {

                                if (result) {
                                    this.refs.pricing.innerHTML = result;
                                    this.setState({ requestDisabled: false });
                                }

                                this.setState({ pricingspinner: 'none' });

                            });

                        }
                    });
                }
            } else {
                //hide the  spinner
                this.setState({ pricingspinner: 'none' });
                this.refs.pricing.innerHTML = "";
            }
        } else {
            //hide the  spinner
            this.setState({ pricingspinner: 'none' });
        }
    }

    onGuestChange = (value) => {
        //alert("guest: " + value)
        this.setState({ guests: value });
    }

    onPetChange = (value) => {
        this.setState({ pets: value });
    }

    setStart = (event) => {
        this.setState({ start: event });
    }
    setEnd = (event) => {
        this.setState({ end: event });
    }

    updateInto = (val) => {
        this.setState({ intro: val.target.value });
    }

    onChange = (e) => {

        this.setState({ arrive: e.value });

        if (this.state.guests) {
            this.getPricing(e.value);
        }

        return false;

    }

    onConditionalFeeClick = (e) => {
        //alert("clciked: " + this.state.condFees);

        if (e.target instanceof HTMLInputElement) {
            //console.log(e.target.value);
            //console.log(e.target.name);

            //if checked add to array else remove
            if (e.target.name == "fee") {
                if (e.target.checked) {
                    //this.state.condFees.push(e.target.value);
                    this.setState({
                        condFeesSelect: [...this.state.condFeesSelect, e.target.value]
                    }, this.getPricing)
                } else {
                    this.setState({
                        condFeesSelect: this.state.condFeesSelect.filter(function (condFees) {
                            return condFees !== e.target.value
                        })
                    }, this.getPricing);
                }
            }
            if (e.target.name == "tax") {
                if (e.target.checked) {
                    this.setState({
                        condTaxesSelect: [...this.state.condTaxesSelect, e.target.value]
                    }, this.getPricing)
                } else {
                    this.setState({
                        condTaxesSelect: this.state.condTaxesSelect.filter(function (condTaxes) {
                            return condTaxes !== e.target.value
                        })
                    }, this.getPricing);
                }
            }
            
        }

        
        
        //alert(e.target.value);
        //alert(e.target.checked);
    }

    //On Promo Code change
    onPromoChange = (e) => {
        this.setState({
            promoCode: e.target.value
        })
    }

    //Apply Promo Code change
    checkPromo = () => {
        //existing promo to reset pricing if no longer valid
        var existingValid = false;
        if (this.state.validPromo != null) {
            existingValid = true;
        }

        //reset the error
        this.setState({
            promoErrorMessage: "",
            showPromoError: false,
            validPromo: null
        })

        //Call to promo to find matching code - must be exact match
        Promo.getPromosDetailByKey("code", this.state.promoCode).then(result => {
            //promo not found
            var valid = true;

            //console.log(result)

            if (result && result.data && typeof (result.data) != 'string' && result.data.length == 1) {
                //console.log("found one");

                var promo = result.data[0];

                //check if promo is valid against all parameters before processing
                //check promo is active and within date
                valid = promo.Active;
                //state and end dates
                valid = valid && (new Date(promo.StartDate) < today);
                valid = valid && (new Date(promo.EndDate) > today);

                //check user is not limited
                if (valid && promo.Users && promo.Users.length > 0) {
                    valid = false;
                    promo.Users.map((user, index) => {
                        //check each user
                        if (user.UserID == this.props.userID) {
                            valid = true;
                        } 
                    })
                }
                //check host not limited
                if (valid && promo.Hosts && promo.Hosts.length > 0) {
                    valid = false;
                    promo.Hosts.map((host, index) => {
                        //check each user
                        if (host.UserID == this.props.ownerID) {
                            valid = true;
                        }

                    })
                }
                //check property is not limited
                if (valid && promo.Listings && promo.Listings.length > 0) {
                    valid = false;
                    promo.Listings.map((listing, index) => {
                        //check each listing
                        if (listing.ListingID == this.props.listingID) {
                            valid = true;
                        }

                    })
                }
                //check state is not limited
                if (valid && promo.States && promo.States.length > 0) {
                    valid = false;
                    promo.States.map((state, index) => {
                        //check each state
                        if (state.Name == this.props.state) {
                            valid = true;
                        }

                    })
                }
                //process this against the pricing function
                if (valid) {
                    this.setState({
                        validPromo: promo
                    })

                    //alert("process pricing");
                    this.getPricing(null);

                } else {
                    // show user promo not found 
                    this.setState({
                        promoErrorMessage: "Promo Code Not Valid",
                        showPromoError: true
                    })

                    if (existingValid) {
                        this.getPricing(null);
                    }
                }
            } else {
                // show user promo not found 
                this.setState({
                    promoErrorMessage: "Promo Code Not Found",
                    showPromoError: true
                })

                if (existingValid) {
                    this.getPricing(null);
                }
            }
        })
    }

    requestBooking = (instant) => {

        //cycle through selected optional fees and taxes
        var condFees = [];
        var condTaxes = [];

        var tempFeeSelect = this.state.condFeesSelect;
        var tempTaxSelect = this.state.condTaxesSelect;

        if (this.state.condfees && this.state.condfees.length > 0 && this.state.condFeesSelect.length > 0) {
            this.state.condfees.map(function (fee, index) {
                if (tempFeeSelect.includes(fee.title)) {
                    //console.log("found fee: " + fee.title);
                    condFees.push(fee);
                }
            })
        }

        if (this.state.condtaxes && this.state.condtaxes.length > 0 && this.state.condTaxesSelect.length > 0) {
            this.state.condtaxes.map(function (tax, index) {
                if (tempTaxSelect.includes(tax.title)) {
                    //console.log("found tax: " + tax.title);
                    condTaxes.push(tax);
                }
            })
        }

        //console.log("create total: " + this.state.total);

        this.props.showRequestToBook && this.props.showRequestToBook(
            instant,
            this.state.dailyPrice,
            this.state.inctaxes,
            this.state.extaxes,
            condTaxes,
            this.state.extaxesArrive,
            this.state.condtaxesArrive,
            this.state.incfees,
            this.state.exfees,
            condFees,
            this.state.exfeesArrive,
            this.state.condfeesArrive,
            this.state.total,
            this.state.deposit,
            this.state.securityDeposit,
            this.state.serviceFee,
            this.state.intro,
            this.state.arrive,
            this.state.depart,
            this.state.guests,
            this.state.pets,
            this.state.Nights,
            this.state.amountSaved,
            this.state.totalGST,
            this.state.guestGST,
            this.state.hostGST,
            this.state.validPromo,
            //this.state.cardFees,
            this.state.pmsFees
        );
    }

    render() {

        return (
            /*<div className={(this.state.scrollStop == 0 ? 'booking-static' : 'booking-float')} >*/
            <div className='booking-static' >
                <div style={{ display: this.state.spinner, width: '100%', textAlign: 'center' }}>
                    <Row className="night-price">
                        <div><b>Building Pricing</b></div>
                    </Row>
                    <Grid
                        color="#00BFFF"
                        height={100}
                        width={100}
                        timeout={0} //in milliseconds
                    />
                </div>
                <div style={{ display: this.state.booking }}>

                    <Row className="night-price">
                        <div><b>{this.state.nightlyFormatted}</b>/night </div>
                    </Row>
                    <Row>
                        <Col className="sm-input">
                            <Datepicker
                                cssClass="md-book-rental"
                                display="anchored"
                                controls={['calendar']}
                                select="range"
                                theme="material"
                                dateFormat="DD/MM/YYYY"
                                themeVariant='light'
                                min={this.state.min}
                                max={this.state.max}
                                rangeHighlight={true}
                                showRangeLabels={true}
                                inRangeInvalid={false}
                                rangeEndInvalid={false}
                                onChange={this.onChange}
                                pages={(window.innerWidth <= 768 ? "1" : "2")}
                                startInput={this.state.start}
                                endInput={this.state.end}
                                labels={this.state.labels}
                                invalid={this.state.invalid}
                                colors={this.state.colors}
                                onPageLoading={this.onCalendarListing}
                                defaultValue={this.state.checkin}
                                maxRange={this.state.maxBooking}
                                minRange={this.state.minBooking}
                            />
                            <Input ref={this.setStart} placeholder="Please Select..." label="Arrive"></Input>
                        </Col>
                        <Col className="sm-input" >
                            <Input ref={this.setEnd} placeholder="Please Select..." label="Depart"></Input>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="sm-input" md="12">
                            <GuestSearch onChange={() => this.getPricing()} onGuestChange={this.onGuestChange} onPetsChange={this.onPetChange} maxGuests={this.state.maxGuest} guests={this.state.guests} pets={this.state.pets} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <Textarea onChange={val => this.updateInto(val)} label="Introduce yourself to the host..." />
                        </Col>
                    </Row>
                    <Row style={{ display: this.state.showDatesNotValid, width: '100%', textAlign: 'center', color: '#e96852' }}>
                        <Col md="12">
                            The dates selected are not all available, please change your dates for pricing.
                        </Col>
                    </Row>
                    <Row style={{ display: this.state.showThirdPartyError, width: '100%', textAlign: 'center', color: '#e96852' }}>
                        <Col md="12">
                            {this.state.thirdPartyErrorMessage}
                        </Col>
                    </Row>
                    <Row>
                        <div style={{ display: this.state.pricingspinner, width: '100%', textAlign: 'center' }}>
                            <div><b>Getting Prices</b></div>
                            <Grid
                                color="#00BFFF"
                                height={50}
                                width={50}
                                timeout={0} //in milliseconds
                            />
                        </div>
                        <div class="clearfix" style={{ display: this.state.showAccordion }}>
                            <div class="pull-left">
                                <div class="payment-list-price-detail-total-price"><b>Total</b></div>
                                <div class="payment-list-price-detail-note">Includes taxes and fees</div>
                            </div>
                        </div>
                        <Accordion style={{ display: this.state.showAccordion }}>
                            <Accordion.Item eventKey="0">
                                {/*<Accordion.Header>{this.state.NightlyTotal}</Accordion.Header>*/}
                                <Accordion.Header><div style={{ width: '100%' }}><div style={{ width: '45%', float: 'left' }}><b>Total Nights: {this.state.Nights}</b></div><div style={{ width: '45%', textAlign: 'right', float: 'left' }}>{this.state.NightlyTotal}</div></div></Accordion.Header>
                                <Accordion.Body>
                                    <div ref="dailyrates"></div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <div ref="pricing" onClick={this.onConditionalFeeClick}></div>
                        {/*<div ref="pricing" ></div>*/}
                        <div class="pull-left" style={{ display: this.state.showPaymentDue }}>
                            <div class="payment-list-price-detail-note">Final payment due {this.state.cancellation} days before stay</div>
                        </div>
                    </Row>
                    <Row style={{ display: this.state.requestDisabled ? "none" : "block" }} >
                        <Row>
                            <Col md="9">
                                <Input id="promocode" label="Promo Code" onChange={this.onPromoChange} errorMessage={this.state.promoErrorMessage} error={this.state.showPromoError} ></Input>
                            </Col>
                            <Col md="3" style={{ paddingTop: "26px", paddingLeft: "0px", paddingRight: "0px", paddingBottom: "26px" }}>
                                <button id="promoapply" onClick={() => this.checkPromo()} className="btn btn-primary" style={{height: "100%"}}>Apply</button>
                            </Col>
                        </Row>
                        <Row style={{ display: !this.state.showPromoError && this.state.validPromo ? "block" : "none" }} >
                            <Col md="12">
                                <div class="clearfix" class="payment-list-price-detail">
                                    <ul>
                                        <li style={{ color: "red" }}>Discount<span>{this.state.totalDsicount}</span></li>
                                        {/*<li style="color:Red">hh</li>*/}
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Row>
                    <Row>
                        <Col md="12">
                            <button id="requesttobook" data-listingid={ this.state.listingID } style={{ display: this.state.showRequest }} onClick={() => this.requestBooking(false)} disabled={this.state.requestDisabled} className="btn btn-secondary btn-block">Request to Book</button>
                            <br />
                            <p style={{ fontSize: "11px" }}>*Your credit card will only be debited if the host accepts the booking. They have 48 hours to do so</p>
                            <button id="instantbook" data-listingid={this.state.listingID} style={{ display: this.state.showInstant }} onClick={() => this.requestBooking(true)} disabled={this.state.requestDisabled} className="btn btn-secondary btn-block">Instant Booking</button>
                            <p style={{ fontSize: "11px", display: this.state.showInstant }}>*The host does have 24 hours to decline the booking if needed and you will not be debited</p>
                        </Col>
                    </Row>
                </div>
                {/*<Row>*/}
                {/*    You won't be charged yet*/}
                {/*</Row>*/}
            </div>
        )
    }
}

export default withRouter(Booking)