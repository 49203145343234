import PMSHostService from "../services/PMSHost";

import { JSEncrypt } from "jsencrypt";

//const otp = 'dGhpc2lzYW90cGVuY29kZWRpbnRvYmFzZTY0';
const otp = 'a927065623ed4e040bcbc60e6f07e26e582b0f75ebcae4eb48e7c7b2bfd863d1';

var publickey = `
-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA1Om+3R0CfYeNVjeUCiCO
jIiGwNlD/deC9iplp7UUmk8mw16iuiIQMP+iXRjkrTHgCu+RwDTsPmfNb0o8xy6S
hRaZupBPwDPhNXlJfo9N+BC8LYmR+saaUBQcXv/40mvoajHGyYs43KMLwFuq2HhK
SaPllOjMDvlxZwVzGK5yr/FGsjQYtN2tXkmBbCfhmFCYEXwl1sCssDLOubWpFwjs
nAfIGzW0PhgSzUzepEAGElIcgb67DN8qwDCiZTUFIq+nbiS1bTi26xmyQDevIbi5
oWojvIofqWnGdCbwiTTQ2vMhDS8QacQD4GnV2y+APB3NU027Ie3XfqCk5zm9R1oN
vQIDAQAB
-----END PUBLIC KEY-----
`;
var privatekey = `
-----BEGIN RSA PRIVATE KEY-----
MIIEowIBAAKCAQEA1Om+3R0CfYeNVjeUCiCOjIiGwNlD/deC9iplp7UUmk8mw16i
uiIQMP+iXRjkrTHgCu+RwDTsPmfNb0o8xy6ShRaZupBPwDPhNXlJfo9N+BC8LYmR
+saaUBQcXv/40mvoajHGyYs43KMLwFuq2HhKSaPllOjMDvlxZwVzGK5yr/FGsjQY
tN2tXkmBbCfhmFCYEXwl1sCssDLOubWpFwjsnAfIGzW0PhgSzUzepEAGElIcgb67
DN8qwDCiZTUFIq+nbiS1bTi26xmyQDevIbi5oWojvIofqWnGdCbwiTTQ2vMhDS8Q
acQD4GnV2y+APB3NU027Ie3XfqCk5zm9R1oNvQIDAQABAoIBAEs82oSadsHTP9/C
vh6iCJ2itTO60ysFmDLTIp8T/1bUN5d8fraVyPD3tPF/FXgakthqdi/cw8r9tXvp
WPbt/zrmlKZ3zqbV7nSOCqmdjGjnyQeygJKE0g0QPkJX5B6/oR69vkiEWTCmsUVl
X0clouLI9x0a8tkrzqYgJ98F7mM7k3cjSdiMIIInegmKa2LD41U6oRik/Qljzxh9
7njCxVMOlEsYI42J9xg0Em9MQPVN7NkBuIpCEY4kp1KdlHDBuT2YbOyeAeOhJaGE
Pph828EIhv+Uk4czhkmtNBiza3P3syO/SUmXUJxvz/dfOiTNQ/zjeHqJXOSwPkoI
V8v6GCUCgYEA+DJ7GXcNuMRcBvdzz6u6kwauy5T3o261Jpms1xEVSlkPy6HOQz7f
VfGCGLzdrgJXHdmMNBETs2V6tQ5PkaqMCL2cME/XJbrOQx/RGBodeoM09RRCQYKS
q1JQ02WuocvdmF0tmsZP3HLr/PYPccOIbgcmv9QRqZSG5F7QbVpSEl8CgYEA25tL
ST0mPhXVEacMpYxskOhKvBT+VML6tJoPOJjNOF2yyMGPVtjCTEhXN+ZcDSpP+fLi
EfGhIaOe4qiFLlVwUuCqidtBudluiezbc6jppmhur1YCqEm3EOes/tGTNClebxqE
e3+cJSzoyliMlES/JdCbhSXNVDz4lxxDT3VF7WMCgYBxGU/PAtlV4OSuqAQ2FeMn
LFxqmK9f4graVBzWxAoXnw37lPgQ3I2Y9Dx8WVQK/zYDfCbPcxivHwcTijnn/vdg
5EZQHZHEt6NWIyF9Ail8sl1ncJvgjkeXmiMKscMqJ2QLEiq95LBvnE69LsNK6/c3
bDrUHycNR4lQd4Qy0H2lsQKBgQDBW/LmAZlygPaiHFveR84vqNhk0nc9WpA0BJHy
W1gWkSmh6guzhBdP40IoTgQu81qH0XuMIRym6dIz7/2T1laYIokF6K/uW6wgdRSk
uc1AYCNU7n5Yfs9ZayO/GfhqrSIg4VBVDP8HHIu73kupIP7M5OiKS8aGrUiUhKYf
iDqR0QKBgC+YpfN1TIGdByW7kuta6VGFoyU4Svhu41pShb/7xK/NDsb7rooBdi+8
JBEUOz9ZfFCWnm6EUESSlN6e3T56BNVHp2D927DPgcIoOiIJOqfJ3oaCO0wbylxz
yoyI9zVwCItK+8juFCwwaLooLHl664df1gxv6CVdC8umr9cTmhKt
-----END RSA PRIVATE KEY-----
`;

const PMSHostFunction = {
    async getPMSHosts() {

        const result = await PMSHostService.getPMSHosts();

        if (result.data.length !== 0) { 
            var decrypt = new JSEncrypt()
            decrypt.setPrivateKey(privatekey)

            for ( var index = 0; index < result.data.length; index++ ) {
                result.data[index]["ClientPassword"] = decrypt.decrypt(result.data[index]["ClientPassword"])
            }

            return result;           
        }

        return undefined;
    },
   
    async createPMSHost(email, clientid, clientpassword, ratetype, pms, media) {
        // encrypt the password before sending
        var encrypt = new JSEncrypt();
        encrypt.setPublicKey(publickey)
        var encrypted = encrypt.encrypt(clientpassword)

        const result = await PMSHostService.createPMSHost(email, clientid, encrypted, ratetype, pms, media);

        if (result.data.length !== 0) { 
            return result;           
        }

        return undefined;
    },

    async updatePMSHost(uuid, email, clientid, clientpassword, ratetype, pms, media) {
        // encrypt the password before sending
        var encrypt = new JSEncrypt();
        encrypt.setPublicKey(publickey)
        var encrypted = encrypt.encrypt(clientpassword)

        const result = await PMSHostService.updatePMSHost(uuid, email, clientid, encrypted, ratetype, pms, media);

        if (result.data.length !== 0) { 
            return result;           
        }

        return undefined;
    },

    async deletePMSHost(uuid) {

        const result = await PMSHostService.deletePMSHost(uuid);

        if (result.data.length !== 0) { 
            return result;           
        }

        return undefined;
    },
}

export default PMSHostFunction