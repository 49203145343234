import { Input, Dropdown, RadioGroup, Radio } from '@mobiscroll/react';
import React, { Component } from 'react';
import { useParams, useLocation } from "react-router-dom";
import { Alert, Button, Card, Col, Modal, Row } from 'react-bootstrap';

import SideNav from "./../modals/SideNav";

import PMSHostDetail from './../functions/PMSHost';
import UserDetail from './../functions/User';

import { FaEdit, FaTrashAlt } from 'react-icons/fa';


export function withRouter(Children) {
    return (props) => {
        return <Children {...props} location={props} />
    }
}

class PMSHosts extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hosts: [],
            loggedIn: false,
            showAlertAddClient: 'none',
            showAddClient: 'block',
            showAlertUpdateClient: 'none',
            input: {},
            errors: {},
            showErrors: {},
            media: false,
            showDetails: 'none',
            showClientID: 'none'
        }
    }
    
    filterByEmail = (e) => {
        const list = this.state.defaultHostList.filter(function (host) { return host.Email.toLowerCase().includes(e.target.value.toLowerCase()); });
        setTimeout(function () { //Start the timer
            this.setState({ hosts: list });
        }.bind(this), 500)
    }

    showAddClientClick = () => {
        this.setState({
            showAddClientModal: true,
            showAddClient: 'block',
            showAlertAddClient: 'none'
        })
    }

    hideAddClientModal = () => {
        this.setState({
            showAddClientModal: false,
            showAlertAddRMSClient: 'none',
        })

        //refresh list
        PMSHostDetail.getPMSHosts().then((hostResult) => {
            if (hostResult && hostResult.data) {
                this.setState({
                    hosts: hostResult.data,
                    defaultHostList: hostResult.data
                });
            }
        })

    }

    showUpdateClientClick = (uuid, email, clientid, clientpassword, ratetype, pms, media) => {
        this.setState({
            showUpdateClientModal: true,
            showAlertUpdateClient: 'none',
            updateUUID: uuid,
            updateEmail: email,
            updateClientID: clientid,
            updateClientPassword: clientpassword,
            updateRateType: ratetype,
            updatePMS: pms,
            updateMedia: media,
            showClientID: 'none'
        })

        let newinput = this.state.input;
        newinput["updateemail"] = email;
        
        newinput["updateclientpassword"] = clientpassword;
        newinput["updateratetype"] = ratetype;
        newinput["updatepms"] = pms;
        newinput["updatemediagroup"] = String(media);

        //check which PMS to only display what is required
        switch (pms) {
            case "RMS":
                newinput["updateclientid"] = clientid;
                this.setState({
                    showClientID: 'block'
                })
                break;
            case "BEDS":

                break;
        }

    }

    hideUpdateClientModal = () => {
        this.setState({
            showUpdateClientModal: false
        })

        //refresh list
        PMSHostDetail.getPMSHosts().then((hostResult) => {
            if (hostResult && hostResult.data) {
                this.setState({
                    hosts: hostResult.data,
                    defaultHostList: hostResult.data
                });
            }
        })
    }

    showDeleteClientClick = (uuid, email) => {
        this.setState({
            showDeleteClientModal: true,
            deleteUUID: uuid,
            deleteEmail: email
        })
    }

    hideDeleteClientModal = () => {
        this.setState({
            showDeleteClientModal: false
        })
    }

    //addMRSCliewnt = () => {
    //    <div style={{ display: this.state.showAddClient, wordWrap: 'break-word' }}>
    //                        <h5>Client ID</h5>
    //                        <Input className="mbsc-remove-margins" type="text" name="addclientid" id="addclientid" errorMessage={this.state.errors.addclientid} error={this.state.showErrors.addclientid} onChange={this.onTextChange} />

    //                        <h5>Client Password</h5>
    //                        <Input className="mbsc-remove-margins" type="text" name="addclientpassword" id="addclientpassword" errorMessage={this.state.errors.addclientpassword} error={this.state.showErrors.addclientpassword} onChange={this.onTextChange} />

    //                    </div>
    //}

    //addBedsClient = () => {

    //}

    addClient = () => {
        
        //check all fields filled
        if (this.getvalidAddClient()) {
            //do work to create
            let input = this.state.input;

            //check for client id before passing in
            var clientID = "";
            if (this.state.showClientID == 'block') {
                clientID = parseInt(input["addclientid"], 10)
            }

            PMSHostDetail.createPMSHost(input["addemail"], clientID, input["addclientpassword"], input["addratetype"], input["addpms"], (input["addmediagroup"] == "true" ? true : false)).then(result => {

                //check the results and only close on success and clear
                if (typeof result != "undefined" && result.data.includes("new pms host created")) {

                    input = {};
                    input["addemail"] = "";
                    input["addclientid"] = "";
                    input["addclientpassword"] = "";
                    input["addratetype"] = "";
                    input["addpms"] = "";
                    input["addmedia"] = "yes";

                    //clear all the fields
                    document.getElementById('addemail').value = "";
                    document.getElementById('addclientid').value = "";
                    document.getElementById('addclientpassword').value = "";
                    document.getElementById('addratetype').value = "";
                    document.getElementById('addpms').value = "";

                    this.setState({
                        showAlertAddClient: 'block',
                        showAlertAddClientError: 'none',
                        showAddClient: 'none',
                        input: input,
                        media: false,
                        showDetails: 'none',
                        showClientID: 'none'
                    })
                } else { //show user there is an issue
                    this.setState({
                        showAlertAddClient: 'none',
                        showAlertAddClientError: 'block',
                        showAddClient: 'none',
                        input: input,
                        media: false,
                        showDetails: 'none',
                        showClientID: 'none'
                    })
                }
            });

            
        }
    }

    updateClient = () => {

        //do work to update
        //check all fields filled
        if (this.getvalidUpdateClient()) {

            //do work to create
            let input = this.state.input;

            //check for client id before passing in
            var clientID = "";
            if (this.state.showClientID == 'block') {
                clientID = parseInt(input["addclientid"], 10)
            }

            PMSHostDetail.updatePMSHost(this.state.updateUUID, input["updateemail"], clientID, input["updateclientpassword"], input["updateratetype"], input["updatepms"], (input["updatemediagroup"] == "true" ? true : false)).then(result => {

                //check the results and only close on success and clear
                if (typeof result != "undefined" && result.data.includes("pms host updated")) {

                    /* Alert the update */
                    this.setState({
                        showAlertUpdateClient: 'block',
                        //input: input
                    })
                }
            });

        }
    }

    deleteClient = () => {
        PMSHostDetail.deletePMSHost(this.state.deleteUUID).then(result => {

            //check the results and only close on success and clear
            if (typeof result != "undefined" && result.data.includes("pms host deleted")) {
                //clear the UUID
                this.setState({
                    deleteUUID: "",
                    deleteEmail: ""
                })

                //close the window
                this.hideDeleteClientModal();

                //refresh list
                PMSHostDetail.getPMSHosts().then((hostResult) => {
                    if (hostResult && hostResult.data) {
                        this.setState({
                            hosts: hostResult.data,
                            defaultHostList: hostResult.data
                        });
                    }
                })
            }
        });

    }

    onTextChange = (event) => {

        let newinput = this.state.input;

        //need to update the state veariables for update only
        switch (event.target.name) {
            case "updateemail":
                this.setState({ updateEmail: event.target.value})
                break;
            case "updateclientid":
                this.setState({ updateClientID: event.target.value })
                break;
            case "updateclientpassword":
                this.setState({ updateClientPassword: event.target.value })
                break;
            case "updateratetype":
                this.setState({ updateRateType: event.target.value })
                break;
            case "addpms":
                this.setState({
                    updatePMS: event.target.value,
                    showDetails: 'block'
                })
                //change the displat pending the PMS chosen
                switch (event.target.value) {
                    case "RMS":
                        this.setState({
                            showClientID: 'block'
                        })
                        break;
                    case "BEDS":
                        this.setState({
                            showClientID: 'none'
                        })
                        break;
                }

                break;
            case "updatemediagroup":
                this.setState({ updateMediaGroup: event.target.value })
                break;
        }

        newinput[event.target.name] = event.target.value;

        if (event.target.value) {
            //remoev the errors fom array state
            let newerrors = {};
            let newshowErrors = {};

            Object.entries(this.state.errors).map((error, index) => {
                if (error[0] !== event.target.name) {
                    newerrors[error[0]] = error[1];
                }
            })
            Object.entries(this.state.showErrors).map((error, index) => {
                if (error[0] !== event.target.name) {
                    newshowErrors[error[0]] = error[1];
                }
            })

            this.setState({
                errors: newerrors,
                showErrors: newshowErrors
            })
        }
    }

    getvalidAddClient = () => {
        //alert('valid');
        let input = this.state.input;
        let errors = {};
        let showErrors = {};
        let isValid = true;

        if (!input["addemail"]) {
            isValid = false;
            errors["addemail"] = "Please enter email.";
            showErrors["addemail"] = true;
        }

        if (typeof input["addemail"] !== "undefined") {

            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(input["addemail"])) {
                isValid = false;
                errors["addemail"] = "Please enter valid email address.";
                showErrors["addemail"] = true;
            }
        }

        if (!input["addclientid"] && this.state.showClientID == 'block') {
            isValid = false;
            errors["addclientid"] = "Please enter client ID.";
            showErrors["addclientid"] = true;
        }

        if (!input["addclientpassword"]) {
            isValid = false;
            errors["addclientpassword"] = "Please enter client password.";
            showErrors["addclientpassword"] = true;
        }

        if (!input["addratetype"]) {
            isValid = false;
            errors["addratetype"] = "Please enter client rate name.";
            showErrors["addratetype"] = true;
        }
        
        if (!input["addpms"]) {
            isValid = false;
            errors["addpms"] = "Please select the PMS";
            showErrors["addpms"] = true;
        }

        this.setState({
            errors: errors,
            showErrors: showErrors
        });

        return isValid;
    }

    getvalidUpdateClient = () => {
        //alert('valid');
        let input = this.state.input;
        let errors = {};
        let showErrors = {};
        let isValid = true;

        if (!input["updateemail"]) {
            isValid = false;
            errors["updateemail"] = "Please enter email.";
            showErrors["updateemail"] = true;
        }

        if (typeof input["updateemail"] !== "undefined") {

            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(input["updateemail"])) {
                isValid = false;
                errors["updateemail"] = "Please enter valid email address.";
                showErrors["updateemail"] = true;
            }
        }

        if (!input["updateclientid"] && this.state.showClientID == 'block') {
            isValid = false;
            errors["updateclientid"] = "Please enter client ID.";
            showErrors["updateclientid"] = true;
        }

        if (!input["updateclientpassword"] && this.state.showClientID == 'block') {
            isValid = false;
            errors["updateclientpassword"] = "Please enter client password.";
            showErrors["updateclientpassword"] = true;
        }

        if (!input["updateratetype"]) {
            isValid = false;
            errors["updateratetype"] = "Please enter client rate name.";
            showErrors["updateratetype"] = true;
        }

        if (!input["updatepms"]) {
            isValid = false;
            errors["updatepms"] = "Please select the PMS";
            showErrors["updatepms"] = true;
        }

        this.setState({
            errors: errors,
            showErrors: showErrors
        });

        return isValid;
    }

    componentDidMount() {

        //get user id by email
        UserDetail.getUserByEmail(this.props.location.state.email).then((result) => {
            if (result.data.LoginType === "admin") {
                //get list of users
                PMSHostDetail.getPMSHosts().then((hostResult) => {
                    if (hostResult && hostResult.data) {
                        this.setState({
                            hosts: hostResult.data,
                            defaultHostList: hostResult.data
                        });
                    }
                })
            }
        })

    }

    render() {
        return (
            <div style={{ paddingTop: "80px" }}>
                <Row>
                    <Col className="d-none d-lg-block" lg="2" xl="1">
                        <SideNav
                            loggedIn={this.props.location.state.loggedIn}
                            loginType={this.props.location.state.loginType}
                            email={this.props.location.state.email}
                            activeTab='hosts'
                        />
                    </Col>
                    <Col md="12" lg="10" xl="11">
                        <div style={{ width: '100%' }}>
                            <h1>PMS Hosts</h1>
                            <hr />
                        </div>

                        <Card>
                            <Card.Body style={{ paddingRight: "0px" }}>
                                <Row>
                                    <Col>
                                        <Input className="mbsc-remove-margins" label="Email Search" inputStyle="box" labelStyle="floating" placeholder="Start Typing" autoComplete="off" onChange={(e) => this.filterByEmail(e)} />
                                        <Button size="sm" onClick={() => this.showAddClientClick()} >Add New Client</Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Row>
                                        <Col md="3" sm="3" xs="3"><h5>Email</h5></Col>
                                        <Col md="2" sm="2" xs="2"><h5>Client ID</h5></Col>
                                        <Col md="2" sm="3" xs="3"><h5>Rate Type</h5></Col>
                                        <Col md="1" sm="1" xs="1"><h5>PMS</h5></Col>
                                        <Col md="1" sm="1" xs="1"><h5>Media</h5></Col>
                                        <Col md="3" sm="2" xs="2"><h5>Actions</h5></Col>
                                    </Row>
                                    {
                                        this.state.hosts && this.state.hosts.length > 0 ?
                                            this.state.hosts.map((host, index) =>
                                                <div style={{ width: '100%' }}>
                                                    <div class="d-none d-md-block">
                                                        <div>
                                                            <Row md="12" sm="12" xs="12" style={{ height: '50px', borderBottom: '1px solid #808080', paddingTop: '6px' }} >
                                                                <Col md="3" sm="3" xs="3">
                                                                    {host.Email}
                                                                </Col>
                                                                <Col md="2" sm="2" xs="2">
                                                                    {host.ClientID}
                                                                </Col>
                                                                <Col md="2" sm="3" xs="3">
                                                                    {host.RateType}
                                                                </Col>
                                                                <Col md="1" sm="1" xs="1">
                                                                    {host.PMS}
                                                                </Col>
                                                                <Col md="1" sm="1" xs="1">
                                                                    {host.Media ? "Yes" : "No"}
                                                                </Col>
                                                                <Col md="3" sm="2" xs="2">
                                                                    <Button variant="link" title="Update" className="icon-button" onClick={() => this.showUpdateClientClick(host.UUID, host.Email, host.ClientID, host.ClientPassword, host.RateType, host.PMS, host.Media)} ><FaEdit size={28} color="lightblue" /></Button>
                                                                    <Button variant="link" title="Delete" className="icon-button" onClick={() => this.showDeleteClientClick(host.UUID, host.Email)} ><FaTrashAlt size={28} color="lightblue" /></Button>
                                                                </Col>
                                                            </Row>
                                                        </div>

                                                    </div>
                                                </div>
                                            ) :
                                            <Row>
                                            </Row>
                                    }

                                </Row>
                            </Card.Body>
                        </Card>

                    </Col>
                </Row>

                <Modal show={this.state.showAddClientModal} size="md" centered>
                    <Modal.Header closeButton onClick={() => this.hideAddClientModal()}>
                        <Modal.Title>Add New Client</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{ display: this.state.showAlertAddClient, width: '100%' }}>
                            <Alert variant="success" >
                                Client Added
                            </Alert>
                        </div>
                        <div style={{ display: this.state.showAlertAddClientError, width: '100%' }}>
                            <Alert variant="danger" >
                                Error creating PMS host
                            </Alert>
                        </div>

                        <div style={{ display: this.state.showAddClient, wordWrap: 'break-word' }}>
                            <h5>PMS</h5>
                            <Dropdown className="mbsc-remove-margins" name="addpms" id="addpms" error={this.state.showErrors.addpms} errorMessage={this.state.errors.addpms} onChange={this.onTextChange} >
                                <option value="" selected>Select PMS</option>
                                <option value="RMS">RMS</option>
                                <option value="BEDS">BEDS24</option>
                            </Dropdown>

                            <div style={{ display: this.state.showDetails }}>
                                <h5>Email</h5>
                                <Input className="mbsc-remove-margins" type="text" name="addemail" id="addemail" errorMessage={this.state.errors.addemail} error={this.state.showErrors.addemail} onChange={this.onTextChange} />

                                <div style={{ display: this.state.showClientID }}>
                                    <h5>Client ID</h5>
                                    <Input className="mbsc-remove-margins" type="text" name="addclientid" id="addclientid" errorMessage={this.state.errors.addclientid} error={this.state.showErrors.addclientid} onChange={this.onTextChange} />
                                </div>

                                <h5>Client Password / Token</h5>
                                <Input className="mbsc-remove-margins" type="text" name="addclientpassword" id="addclientpassword" errorMessage={this.state.errors.addclientpassword} error={this.state.showErrors.addclientpassword} onChange={this.onTextChange} />

                                <h5>Rate Name</h5>
                                <Input className="mbsc-remove-margins" type="text" name="addratetype" id="addratetype" errorMessage={this.state.errors.addratetype} error={this.state.showErrors.addratetype} onChange={this.onTextChange} />
                                <div style={{ display: this.state.showClientID }}>
                                    <h5>Auto Update Media</h5>
                                    <Row>
                                        <Col sm={12} md={6}>
                                            <RadioGroup name="addmediagroup">
                                                <Radio label="Yes" defaultChecked={this.state.media} value="true" id="addmediayes" onChange={this.onTextChange} />
                                            </RadioGroup>
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <RadioGroup name="addmediagroup">
                                                <Radio label="No" defaultChecked={!this.state.media} value="false" id="addmediano" onChange={this.onTextChange} />
                                            </RadioGroup>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <Button variant="success" onClick={() => this.addClient()} style={{ width: "100%" }}>Create New Client</Button>
                        <br />
                        <br />
                        <Button variant="secondary" onClick={() => this.hideAddClientModal()} style={{ width: "100%" }}>Close</Button>
                    </Modal.Body>
                </Modal>                

                <Modal show={this.state.showUpdateClientModal} size="md" centered>
                    <Modal.Header closeButton onClick={() => this.hideUpdateClientModal()}>
                        <Modal.Title>Update Client - {this.state.updateEmail}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{ display: this.state.showAlertUpdateClient, width: '100%' }}>
                            <Alert variant="success" >
                                Client Updated
                            </Alert>
                        </div>
                        <div style={{ wordWrap: 'break-word' }}>
                            <h5>Email</h5>
                            <Input className="mbsc-remove-margins" value={this.state.updateEmail} type="text" name="updateemail" id="updateemail" errorMessage={this.state.errors.updateemail} error={this.state.showErrors.updateemail} onChange={this.onTextChange} />

                            <div style={{ display: this.state.showClientID }}>
                                <h5>Client ID</h5>
                                <Input className="mbsc-remove-margins" value={this.state.updateClientID} type="text" name="updateclientid" id="updateclientid" errorMessage={this.state.errors.updateclientid} error={this.state.showErrors.updateclientid} onChange={this.onTextChange} />
                            </div>

                            <h5>Client Password / Token</h5>
                            <Input className="mbsc-remove-margins" value={this.state.updateClientPassword} type="text" name="updateclientpassword" id="updateclientpassword" errorMessage={this.state.errors.updateclientpassword} error={this.state.showErrors.updateclientpassword} onChange={this.onTextChange} />

                            <h5>Rate Name</h5>
                            <Input className="mbsc-remove-margins" value={this.state.updateRateType} type="text" name="updateratetype" id="updateratetype" errorMessage={this.state.errors.updateratetype} error={this.state.showErrors.updateratetype} onChange={this.onTextChange} />

                            {/*<h5>PMS</h5>*/}
                            {/*<Dropdown className="mbsc-remove-margins" value={this.state.updatePMS} name="updatepms" id="updatepms" error={this.state.showErrors.updatepms} errorMessage={this.state.errors.addpms} onChange={this.onTextChange} >*/}
                            {/*    <option value="" selected>Select PMS</option>*/}
                            {/*    <option value="RMS">RMS</option>*/}
                            {/*</Dropdown>*/}
                            <div style={{ display: this.state.showClientID }}>
                            <h5>Auto Update Media</h5>
                                <Row>
                                    <Col sm={12} md={6}>
                                        <RadioGroup name="updatemediagroup">
                                            <Radio label="Yes" defaultChecked={this.state.updateMedia} value="true" id="updatemediayes" onChange={this.onTextChange} />
                                        </RadioGroup>
                                    </Col>
                                    <Col sm={12} md={6}>
                                        <RadioGroup name="updatemediagroup">
                                            <Radio label="No" defaultChecked={!this.state.updateMedia} value="false" id="updatemediano" onChange={this.onTextChange} />
                                        </RadioGroup>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <hr />
                        <Button variant="success" onClick={() => this.updateClient()} style={{ width: "100%" }}>Update Client</Button>
                        <br />
                        <br />
                        <Button variant="secondary" onClick={() => this.hideUpdateClientModal()} style={{ width: "100%" }}>Close</Button>
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.showDeleteClientModal} size="md" centered>
                    <Modal.Header closeButton onClick={() => this.hideDeleteClientModal()}>
                        <Modal.Title>Delete Client - {this.state.deleteEmail}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{ wordWrap: 'break-word' }}>
                            <h4>Page will refresh</h4>
                            You will not be able to undo this action and all client listings will be deleted
                        </div>
                        <hr />
                        <Button variant="success" onClick={() => this.deleteClient()} style={{ width: "100%" }}>Confirm Delete</Button>
                        <br />
                        <br />
                        <Button variant="secondary" onClick={() => this.hideDeleteClientModal()} style={{ width: "100%" }}>Close</Button>
                    </Modal.Body>
                </Modal>

            </div>
        )
    }
}

export default withRouter(PMSHosts)