import axios from 'axios';

import { BASE_URL } from './GlobalDefinitions';
import { AUTH, CONTENT_TYPE, METHODS_DELETE, METHODS_GET, METHODS_POST, METHODS_PUT, ORIGIN } from './Headers';

const USER_API_URL = "/user";
const USERS_API_BASE_URL = "/users";
//const USER_EMAIL_API_BASE_URL = "/usersemail";
//const USER_ID_API_BASE_URL = "/usersid";
//const USER_IMAGE_BASE_URL = "/usersimage";
//const USER_PASSWORD_BASE_URL = "/userspassword";
//const USER_VERIFY_BY_TOKEN = "/usertoken";
//const USER_VERIFY_BY_UUID = "/userverify";
//const USER_RESEND_WELCOME_EMAIL = "/userwelcome";



class UserService {
    // get all users
    /*getUsers() {
        return axios.get(BASE_URL + USERS_API_BASE_URL, {
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }*/

    /**
     * get all users in the system
     * @returns all users
     */
    getUsers() {
        return axios.get(BASE_URL + USERS_API_BASE_URL, {
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    // create a new user
    /*createUser(user) {
        return axios.post(BASE_URL + USERS_API_BASE_URL, null, { 
            params: {
                User: user
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_POST,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }*/
    /**
     * create a new user and return the result
     * @param {string} email 
     * @param {string} firstname 
     * @param {string} lastname 
     * @param {string} hashedpassword 
     * @param {string} loginType 
     */
    createUser(email, firstname, lastname, hashedPassword, loginType) {
        return axios.post(BASE_URL + USER_API_URL, {"Email": email, "Firstname": firstname, "Lastname": lastname, 
            "Password": hashedPassword, "LoginType": loginType}, {
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_POST,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    // get user by primary key - Email
    /*getUserByEmail(email) {
        return axios.get(BASE_URL + USER_EMAIL_API_BASE_URL, {
            params: {
                Email: email
            },
            headers: config
            
        });
    }*/

    /**
     * 
     * @param {string} email 
     * @returns the user
     */
    getUserByEmail(email) {
        return axios.get(BASE_URL + USER_API_URL, {
            params: {
                Key: "GET",
                ParamKey: "Email",
                ParamValue: email
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    /*// get user by Username
    getUserByID(id) {
        return axios.get(BASE_URL + USER_ID_API_BASE_URL, {
            params: {
                ID: id
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }*/

    /**
     * get user by uuid
     * @param {string} uuid
     */
    getUserByID(id) {
        return axios.get(BASE_URL + USER_API_URL, {
            params: {
                Key: "GET",
                ParamKey: "UUID",
                ParamValue: id
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    // update an existing user
    /*updateUser(user) {        
        return axios.put(BASE_URL + USERS_API_BASE_URL, user, {
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_PUT,
                'Access-Control-Allow-Origin': ORIGIN,
                "Content-Type": CONTENT_TYPE
            }
        });
    }*/
    /**
     * update an existing user
     * @param {string} email 
     * @param {string} firstname 
     * @param {string} lastname
     * @param {string} phone
     * @param {string} nativelanguage 
     * @param {string} otherlanguage
     * @param {string} city 
     * @param {string} state
     * @param {string} country
     * @param {string} bio 
     * @param {string} displayname
     * @param {string} image 
     * @param {string} bsb 
     * @param {string} accountno
     */
     updateUser(email, firstname, lastname, phone, nativelanguage, otherlanguage, 
        city, state, country, bio, displayname, image, bsb, accountno) {
         
            return axios.put(BASE_URL + USER_API_URL, {"Email": email, "Firstname": firstname, "Lastname": lastname, 
            "Phone": phone, "NativeLanguage": nativelanguage, "OtherLanguage": otherlanguage, "City": city, 
            "State": state, "Country": country, "Bio": bio, "DisplayName": displayname, 
            "Base64": image, "BSB": bsb, "AccountNo": accountno}, {
            params: {
                Key: "FULL",
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_PUT,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    /**
     * verify a user account, set the valid flag to true
     * @param {string} uuid 
     * @returns 
     */
    verifyAccount(uuid) {
        //return true;
        return axios.put(BASE_URL + USER_API_URL, null, {
            params: {
                Key: "VERIFY",
                ID: uuid,
                Valid: true
            },
            headers: {
                'Authorization': AUTH,
                'Access-Control-Allow-Methods': METHODS_POST,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    // delete the image from the user
    /*deleteImage(email) {
        return axios.delete(BASE_URL + USER_API_URL, {
            params: {
                Email: email
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_DELETE,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }*/

    /**
     * delete the image for this user
     * @param {string} email 
     * @returns 
     */
    deleteImage(email) {
        return axios.delete(BASE_URL + USER_API_URL, {
            params: {
                Key: "IMAGE",
                ParamKey: "Email",
                ParamValue: email
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_DELETE,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    // delete user
    /*deleteUser(email) {
        return axios.delete(BASE_URL + USERS_API_BASE_URL, {
            params: {
                Email: email
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_DELETE,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }*/

    /**
     * delete the user from the system
     * @param {string} email 
     * @returns 
     */
    deleteUser(email) {
        return axios.delete(BASE_URL + USER_API_URL, {
            params: {
                Key: "USER",
                ParamKey: "Email",
                ParamValue: email
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_DELETE,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    // update an existing user
    /*updatePassword(password) {
        return axios.put(BASE_URL + USER_PASSWORD_BASE_URL, password, {
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_PUT,
                'Access-Control-Allow-Origin': ORIGIN,
                "Content-Type": CONTENT_TYPE
            }
        });
    }*/

    /**
     * update an existing user account with a new password
     * @param {string} password
     * @param {string} uuid
     * @returns 
     */
    updatePassword(password, uuid) {
        return axios.put(BASE_URL + USER_API_URL, null, {
            params: {
                Key: "PASSWORD",
                Password: password,
                ID: uuid
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_PUT,
                'Access-Control-Allow-Origin': ORIGIN,
                "Content-Type": CONTENT_TYPE
            }

        });
    }

    // get user by primary key - Email
    /*userCheckByToken(token) {
        return axios.get(BASE_URL + USER_VERIFY_BY_TOKEN, {
            params: {
                Token: token
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }*/

    /**
     * verify user by token
     * @param {string} token 
     * @returns 
     */
    userCheckByToken(token) {
        return axios.get(BASE_URL + USER_API_URL, {
            params: {
                Key: "TOKEN",
                ParamKey: "",
                ParamValue: token
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    /*resetPassword(email) {
        return axios.post(BASE_URL + USER_EMAIL_API_BASE_URL, null, { 
            params: {
                Email: email
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_POST,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }*/

    /**
     * send an email to reset the password
     * @param {string} email 
     * @returns 
     */
    resetPassword(email) {
        return axios.put(BASE_URL + USER_API_URL, null, { 
            params: {
                Key: "RESET",
                Email: email
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_POST,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    /*resendWelcomeEmail(userid) {
        return axios.get(BASE_URL + USER_RESEND_WELCOME_EMAIL, {
            params: {
                UserID: userid
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }*/

    /**
     * resend a user welcome email based in uuid
     * @param {string} userid 
     * @returns 
     */
    resendWelcomeEmail(userid) {
        return axios.get(BASE_URL + USER_API_URL, {
            params: {
                Key: "WELCOME",
                ParamKey: "UUID",
                ParamValue: userid
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

}

const userServce = new UserService();

export default userServce