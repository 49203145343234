import React, { useState, useEffect } from 'react';

import User from './../functions/User';

const UserName = (props) => {
    const [name, setName] = useState(false);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {

        if (props.id && !loaded) {
            User.getUserByID(props.id).then(result => {
                setName(result.data.Firstname + " " + result.data.Lastname);
            });
            setLoaded(true);
        }
    })

    return (
        <div>
            {name}
        </div>
    )
}

export default UserName;